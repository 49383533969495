.ticket-container {
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - 64px);
  margin: 0;
}
@media (min-width: 768px) {
  .ticket-container {
    width: 80vw;
    max-width: 600px;
    margin: 0 auto;
  }
}
img {
  width: 66%;
}
.qr-code {
  display: flex;
  align-items: center;
  justify-content: center;
}
.q-card__section {
  margin: 0;
  padding: 5px 16px;
}
.ticket-id {
  background: #ccc;
  border-radius: 10px;
}
.bubbles {
  position: fixed;
  padding: 0;
  height: 7vh;
  z-index: 2;
  left: 0;
  right: 0;
}
.security-container {
  position: relative;
  width: 200vw;
  height: 7vh;
  max-height: 7vh;
  margin-left: -50vw;
  color: #000;
  z-index: 900;
}
.security-word {
  position: fixed;
  width: 100vw;
  margin-left: 50vw;
  height: 100%;
  font-size: 200%;
  z-index: 950;
}
.security-word .word {
  opacity: 1;
}
.security-word .time {
  opacity: 0;
}
.security-word .word,
.security-word .time {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 990;
  transition: opacity 0.5s;
  transform: translateX(-50%) translateY(-50%);
}
.showTime .word {
  opacity: 0;
}
.showTime .time {
  opacity: 1;
}
.valid-from {
  font-size: 140%;
  color: #000;
}
.adults-price {
  font-size: 110%;
  color: #555;
  font-weight: 500;
  padding: 0 15px;
}
/*# sourceMappingURL=src/pages/tickets/show/index.css.map */