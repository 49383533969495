





































































.ticket
  background-color #f6f6f6

.expired
  .background
    &:before,&:after
      content ''
      position absolute
      top 0
      bottom 0
      left 0
      right 0
      z-index 0
    &:after
      background-position top
      background-repeat repeat-y
      background-image linear-gradient(10deg, #f6f6f6 55%, #f6f6f620 100%);
    &:before
      background-image url('~assets/Crossed Stripes.svg')
      opacity 0.35
      background-size 100%

.statusIndicator
  position absolute
  top 0
  right 0
  width 42px
  height 42px
  color white
  clip-path polygon(100% 0, 0 0, 100% 100%)
  *
    position absolute
    top 12.5%
    right 12.5%
