



















































































.buy-ticket-footer
  padding 10px 10px 64px 10px
  background white
.buy-ticket
  width 100%
